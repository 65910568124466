<!-- https://tailwindui.com/components/application-ui/navigation/navbars 'simple'-->
<template>
  <Disclosure as="nav" class="shadow z-0 bg-parchment" v-slot="{ open }">
    <TopLeftTriangles />
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 z-0">
      <div class="relative flex h-16 justify-between">
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="hidden sm:hidden md:flex md:ml-56 sm:flex sm:space-x-2 lg:space-x-4 lg:ml-56 xl:space-x-8">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <router-link v-for="route in routeList" :key="route.id" :to="{ name: route.routeName }" class="nav-btn-inactive" active-class="nav-btn-active" exact>
                {{ route.label }}
            </router-link>
          </div>
        </div>
        <div class="static right-0 flex items-center -ml-32 space-x-2 pr-2 pr:0">
          <!--- social media / contact -->
          <div class="flex items-center md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span class="sr-only">Navigation Menu</span>
              <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div class="mr-10 text-darcy-blue font-bold hidden lg:flex -mr-0 xl:mr-12"><a href="mailto:booking@samuelknowlton.com">booking@samuelknowlton.com</a></div>
          <div class="mr-10 text-darcy-blue font-semibold hidden xl:flex">512-814-8022</div>
          <SocialMedia icon="fab fa-instagram" classText="mx-2 text-darcy-blue" href="https://www.instagram.com/lowrentdarcy/" title="Samuel Knowlton on Instagram" />
          <SocialMedia icon="fab fa-facebook-f" classText="mx-2 text-darcy-blue" href="https://www.facebook.com/DiscountDarcy/" title="Samuel Knowlton on Facebook" />
          <SocialMedia icon="fab fa-twitter" classText="mx-2 text-darcy-blue" href="https://x.com/MordantWastrel" title="Samuel Knowlton on X"/>  
          <img class="hidden sm:block h-10 w-auto" src="/assets/images/flanders-navbar.png" alt="Samuel Knowlton" />   
        </div>
      </div>
    </div>

    <DisclosurePanel class="absolute right-0 md:hidden z-20">
      <div class="space-y-1 pb-2 pt-2 z-25 bg-white">
        <DisclosureButton v-for="route in routeList" :key="route.id" class="mobile-menu-inactive" active-class="mobile-menu-active" @click="navigateTo(route.routeName)">
          {{ route.label }}
        </DisclosureButton>
      </div>
   </DisclosurePanel>

</Disclosure>
</template>
<script>
export default {
    data() {
        return {
            routeList: [
                { id: 0, label: 'Home', routeName: 'Home' },
                { id: 1, label: 'About', routeName: 'About' },
                { id: 2, label: 'Clients', routeName: 'Clients' },
                { id: 3, label: 'Contact', routeName: 'Contact' }
            ],
            activeButton: 0
        }
    },
    methods: {
        setActiveButton(button) {
            this.activeButton = button.id
        },
        navigateTo(routeName) {
         console.log('Navigating to:', routeName);
         this.$router.push({ name: routeName });
      }
    }
}
</script>
<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import TopLeftTriangles from "@/components/TopLeftTriangles.vue";
import SocialMedia from "@/components/sectionComponents/SocialMedia.vue";
</script>