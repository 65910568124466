<template>
    <HeaderComponent />
    <div class="section flex items-center relative overflow-hidden min-h-screen px-8 py-4 z-5">
    <BottomRightTriangles />
    <div class="sm:flex w-full h-full">
        <div class="basis-1/2 ml-1 w-full">
            <!-- Page Header -->
            <DarcyHeader />
            <div class="basis-1/2 items-center justify-center w-full pt-2 ml-24 z-10 block sm:hidden"> <!--- Frame image only below small breakpoint -->
                <img class="max-h-[65rem] max-w-[52rem] -ml-28 h-[30rem] w-[24rem] mt-56 xs2:mt-24 xs2:-ml-12" src="@/assets/images/PemberleyStudio.gif" alt="The Studio at Derpington Manor" />
            </div>
            <!-- Content Area: About Darcy -->
            <div class="w-full max-w-6xl mt-4 sm:mt-72 ml-0 sm:ml-6 lg:mt-28 lg:ml-16 3xl:mt-32 text-center">
                <blockquote class="font-bold font-mono">"Samuel Knowlton is the quintessential Mr. Darcy."</blockquote>
                <div class="ml-0 text-sm">-- <i>Miss Bennet</i>, The Sun News Network</div>
                <blockquote class="mt-4 font-bold font-mono">"Mm-hmm."</blockquote>
                <div class="text-sm -ml-6">-- Sam's Mother</div>
                <blockquote class="mt-4 font-bold font-mono text-lg text-darcy-blue">Commercial. Narration. eLearning. Games.</blockquote>
            </div>
            <div class="flex flex-row w-full mt-8 ml-0 sm:ml-16">
                <div class="basis-1/2 border-r-4 border-dotted border-darcy-blue pr-2">
                    <fieldset class="border-t border-darcy-blue">
                        <legend class="mx-auto px-4 text-2xl italic">Performer &amp; Entrepreneur</legend>
                        <p class="pt-2">Sam is a twenty year veteran entrepreneur and actor with union and nonunion stage and voiceover credits.</p>
                        <p class="pt-2">Sam is a Delaware native, and like everyone in Delaware, misses Rehoboth Beach and grew up down the street from Joe Biden and Aubrey Plaza. Sam holds a BA from Cornell University and brings a range of interests and experience to bear on his work: Sam is a dad, a lifelong pianist and singer, a private pilot, a cult follower of Scott Bradlee's Postmodern Jukebox, an amateur rider of English motorcycles, a software architect, and a business owner. His interests include medieval European and Japanese history, Neal Stephenson, dialect work, Texas BBQ, and writing. He speaks French and Italian (with copy!)</p>
                        <p class="pt-4">Based in Austin, Sam has clients in New York, Los Angeles, Chicago, Miami, and San Francisco. Sam returned to the VO industry in 2024 and has trained with Lindsay Sheppard, Bradford Hastings, and Lena Hill. </p>
                    </fieldset>
                </div>
                <div class="basis-1/2 ml-2">
                    <fieldset class="border-t border-darcy-blue">
                        <legend class="mx-auto px-4 text-2xl italic">The Studio @ Derpington Manor</legend>
                        <div class="pt-4">
                            <img class="ml-2 lg:ml-16 3xl:ml-40 mb-4" src="/assets/images/sc.jpg" alt="A Source-Connect Studio" />
                            <ul>
                                <li><b>Microphones:</b> Sennheiser 416, Shure SM7B, Shure Beta 27, Apogee HypeMic (mobile)</li>
                                <li><b>Pre:</b> Focusrite Clarett+</li>
                                <li><b>DAW:</b> Reaper</li>
                                <li><b>Equipment:</b> Shure SRH840A Over-ear, closed-back cans, Mogami Gold cabling, Custom-built PC</li>
                                <li><b>Environment:</b> -60db Noise Floor; Gain Control from booth; Hardwired 5 Gigabit Fiber Internet</li>
                                <li><b>Connection Options:</b> SourceConnect Pro, Zoom, Meet, Microsoft Teams, Discord</li>
                            </ul>
                        </div>
                        <div class="pt-4">Need a sassy female? Check out <a href="https://theladyderp.com/voice-over/" class="underline" target="_blank">The Lady Derp</a> in the same studio!</div>
                    </fieldset>
                </div>
            </div>
        </div>
        <div class="basis-1/2 items-center justify-center w-full md:w-5/12 h-[70rem] w-[46rem] md:ml-8 2xl:ml-42 z-10 hidden sm:block">
            <img class="max-h-[53rem] max-w-[42rem] h-[37rem] w-[30rem] sm:ml-0 mt-20 md:h-[45rem] md:w-[36rem] lg:h-[55rem] lg:w-[44rem] xl:h-[68rem] xl:w-[55rem] lg:ml-14 lg:mt-" src="@/assets/images/PemberleyStudio.gif" alt="The Studio at Derpington Manor" title="The Studio at Derpington Manor" />
        </div>
        <!--
                <div class="basis-1/2 items-center justify-center w-full md:w-5/12 h-[70rem] w-[46rem] md:-ml-8 lg:ml-8 2xl:ml-42 z-10 hidden sm:block">
        <img class="max-h-[70rem] max-w-[56rem] ] -ml-36 h-[35rem] w-[28rem] sm:h-[50rem] sm:w-[40rem] ml-10 -mt-32 sm:mt-12 lg:h-[55rem] lg:w-[44rem] lg:mt-16 xl:h-[68rem] xl:w-[55rem] lg:-ml-8 xl:-mt-8" src="@/assets/images/svg/samuel.svg" alt="Artist's Rendering of Discount Darcy" />
        </div>
        -->
        </div>
    </div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent.vue";
import DarcyHeader from "@/components/DarcyHeader.vue";
import BottomRightTriangles from "@/components/BottomRightTriangles.vue";
export default {
    name: 'About-View',
    components: {
        HeaderComponent,
        DarcyHeader,
        BottomRightTriangles
    }
}
</script>